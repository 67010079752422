<template>
  <div id="home" class="view-home" @scroll="handleScroll">
    <!-- <MeanHeader /> -->
    <!-- <div style="height: 0.44rem; width: 100%"></div> -->
    <Swiper />
    <div class="home-notice-bar-wrap">
      <NoticeBar
        mode=""
        color="#C6C6C6"
        background="#010101"
        v-if="global_info.site_bulletin"
        left-icon="volume-o"
        class="home-notice-bar"
        :text="global_info.site_bulletin"
      />
    </div>
    <div class="home-tab-box-wrap">
      <div class="home-tab-box">
        <div class="tab-list">
          <div
            v-for="item in ['盲盒开箱', '积分玩法']"
            :key="item"
            @click="activeTab = item"
            :class="{ 'active-item': activeTab == item }"
            class="tab-item"
          >
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="home-box">
      <div class="home-List-box">
        <div v-for="(item, index) in finalHotList" :key="index + 'a'">
          <div v-if="item.src" class="Title">
            <img :src="item.src" class="play-title" alt="" />
            <div class="triangle"></div>
          </div>
          <div v-if="item.box.length" class="home-List">
            <div
              class="home-list-item"
              v-for="(item, index) in item.box"
              :key="index"
              @click="ToOpen(item.id, item.integralId)"
            >
              <div class="tagbox">
                <img
                  v-if="item.tag == 2"
                  src="../../assets/images/mobile/tagnew.png"
                  alt=""
                />

                <img
                  v-if="item.tag == 1"
                  src="../../assets/images/mobile/taghot.png"
                  alt=""
                />
              </div>

              <div class="left-icon"></div>
              <div class="wrap_pic">
                <img v-lazy="item.cover" />
                <div class="weapon_cover">
                  <img :src="item.weapon_cover" alt="" />
                </div>
              </div>
              <div class="Blind_btn">
                <span>{{ item.name }}</span>
              </div>
              <div class="box-price">
                <Cprice v-if="activeTab === '盲盒开箱'" :price="item.bean" />
                <Cintegral v-else :price="item.bean" />
              </div>
            </div>
          </div>
        </div>
        <!-- 隐藏转盘 -->
        <div v-if="activeTab === '积分玩法'">
          <div class="Title" v-if="turntabllist">
            <img
              src="../../assets/images/mobile/fuliboxtitle.png"
              class="play-title"
              alt=""
            />
            <div class="triangle"></div>
          </div>
          <div class="home-List" v-if="turntabllist">
            <div class="home-list-item" @click="tokaijiang()">
              <div class="left-icon"></div>
              <div class="wrap_pic">
                <img v-lazy="turntabllist.cover" />
                <div class="weapon_cover">
                </div>
              </div>
              <div class="Blind_btn">
                <span>{{ turntabllist.name }}</span>
              </div>
              <div class="box-price">
                <Cintegral :price="turntabllist.score" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PublicFooter />
    <!-- <div v-else class="home-integral-box">积分箱子</div> -->
    <Tabbar :activeIndex="0" />
    <Rank :right="sidebarTransform" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {
  GetHotBox,
  GetIntegralBoxApi,
  Rooms,
  Info,
  getturntableApi,
} from "@/network/api.js";
import PublicFooter from "@/components/common/PublicFooter/PublicFooter.vue";
import { NoticeBar } from "vant";
import MeanHeader from "@/components/common/MeanHeader.vue";
import Swiper from "@/components/common/NavHeader/Swiper.vue";
import Rank from "@/views/Rank/index.vue";
export default {
  components: {
    NoticeBar,
    MeanHeader,
    Swiper,
    Rank,
    PublicFooter,
  },
  name: "Home",
  data() {
    return {
      HotList: [],
      integralBoxList: [],
      BladeList: [],
      recruitList: [],
      classicList: [],
      vitalityList: [],
      hotboxlist: [],
      time: 10000,
      Headlines: [],
      soulList: [],
      XyList: [],
      swiperIndex: 0,
      swiperList: [],
      // playingList: playingList,
      playingActive: null,
      // 侧边栏
      isScrolling: false,
      scrollTimer: null,
      sidebarTransform: "",
      activeTab: "盲盒开箱",

      turntabllist: [],
    };
  },

  created() {
    this.getBannerList();
    this.ObtainHotBox();
    this.GetIntegralBoxFun();
    this.GetRooms();
    // 读取本地滚动条
    const a = localStorage.getItem("scroll" || 0);
    if (a) {
      //读取本地的scroll
      setTimeout(() => {
        window.scrollTo({
          top: a,
          left: 0,
          behavior: "instant",
        });
        localStorage.setItem("scroll", 0);
      }, 300);
    }
  },

  filters: {
    GuoLvOne(val) {
      if (val.length > 14) {
        return val.substr(0, 14) + "...";
      } else {
        return val;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      document.getElementById("ymwl-kefu").style.display = "block";
      document.getElementById("ymwl-kefu").classList.remove("my-custom-styles");
      document.getElementById("ymwl-kefu").classList.add("my-custom-styles1");
    }, 100);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    tokaijiang() {
      if (!this.user.id) {
        this.$message.error("请先登录");
        return false;
      } else {
        this.$router.push({
          path: "/kaijian",
        });
      }
    },
    handleScroll() {
      this.isScrolling = true;
      this.sidebarTransform = "-0.25rem";
      clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => {
        this.isScrolling = false;
        this.sidebarTransform = "";
      }, 500); // 设置一个延时，判断用户停止滚动
    },
    getBannerList() {
      Info().then((res) => {
        // console.log(res.data.data.banners, 'banners');
        this.swiperList = res.data.data.banners;
        this.swiperList = this.swiperList.map((item) => {
          return {
            ...item,
            href: item?.href?.replace("tmskins", "m.tmskins"),
          };
        });
        // this.swiperList = [
        //   {
        //     href: "https://m.tmskins.com",
        //     image: require("../../assets/images/mobile/m-banner-0.png"),
        //     title: "banner0"
        //   },
        //   {
        //     href: "https://m.tmskins.com/Roll",
        //     image: require("../../assets/images/mobile/m-banner-1.png"),
        //     title: "banner1"
        //   },
        //   {
        //     href: "https://m.tmskins.com/shareReward",
        //     image: require("../../assets/images/mobile/m-banner-2.png"),
        //     title: "banner2"
        //   },
        //   {
        //     href: "https://m.tmskins.com/shareReward",
        //     image: require("../../assets/images/mobile/m-banner-3.png"),
        //     title: "banner3"
        //   },
        // ];
        // this.swiperList = [
        //   { image: 'https://api.acekeys.net/uploads/20240129/04908e4e89fda46c6ce2b775f4a2ef5e.png' },
        //   { image: 'https://api.acekeys.net/uploads/20240129/1ebfbd25566a06476026da19d9ef3abb.png' },
        //   { image: 'https://api.acekeys.net/uploads/20240129/4347103b1d1fdcffb249eb3992aa9e13.png' }
        // ];
      });
    },
    // 写死跳转roll房
    goroll(item) {
      if (!this.user.id) {
        this.$message.error("请先登录");
        return false;
      }
      location.href = item.href;
      // this.$router.push('/Roll');
    },
    gotoActiveChange(item) {
      this.$router.push(item.page);
    },
    playActiveChange(index) {
      this.playingActive = index;
    },
    onChange(index) {
      this.swiperIndex = index;
    },
    onChange1(index) {
      this.$refs.swiper.swipeTo(index);
    },
    //积分宝箱
    GetIntegralBoxFun() {
      GetIntegralBoxApi().then((res) => {
        this.integralBoxList = res.data.data;
        console.log("积分宝箱列表", this.integralBoxList);
        this.integralBoxList = [
          {
            id: "999",
            name: "积分宝箱",
            src: require("../../assets/images/mobile/pointboxtitle.png"),
            box: res.data.data.map((item) => {
              return {
                bean: "",
                cate_id: "999",
                cover: item.box.cover,
                id: item.box.id,
                integralId: item.id,
                name: item.name,
                bean: item.box.bean,
                weapon_cover: "",
              };
            }),
          },
        ];
      });
      // 转盘列表
      getturntableApi().then((res) => {
        console.log(res.data, "转盘");
        this.turntabllist = res.data.data;
      });
    },

    //热门宝箱
    ObtainHotBox() {
      GetHotBox().then((res) => {
        this.HotList = res.data.data;
        console.log("热门宝箱列表", this.HotList);
      });
    },
    //开箱
    ToOpen(id, integralId = "") {
      // 记录当前滚动位置
      localStorage.setItem("scroll", document.documentElement.scrollTop);
      let query = {
        id,
      };
      if (integralId) {
        query.typeid = integralId;
      }
      if (this.user.id) {
        query.userid = this.user.id;
      }
      this.$router.push({
        path: "/OpeningBox",
        query,
      });
    },

    //房间
    GetRooms() {
      Rooms("1").then((res) => {
        let RoomList = res.data.data.data;
        // function compare(property) {
        // 	return function(a, b) {
        // 		let value1 = Number(a[property]);
        // 		let value2 = Number(b[property]);
        // 		return value1 - value2
        // 	}
        // }
        let haveHand = RoomList.filter((item) => {
          return item.status_alias == "进行中";
        });
        this.Headlines = haveHand.slice(0, 3);
      });
    },

    //前往房间
    toWelfareInfo(id) {
      this.$router.push({
        path: "/room",
        query: {
          id: id,
        },
      });
    },

    ...mapMutations([
      "User",
      "GetIndividualsShow",
      "GetRegisterShow",
      "GetSignInShow",
    ]),
  },

  computed: {
    ...mapState([
      "user",
      "global_info",
      "IndividualsShow",
      "RegisterShow",
      "SignInShow",
    ]),
    finalHotList() {
      let list = this.HotList;
      if (this.activeTab === "积分玩法") {
        list = this.integralBoxList;
      }
      return list;
    },
  },
};
</script>
<style lang="scss">
.view-home {
  .home-notice-bar-wrap {
    padding: 0 0.2rem;
    margin-bottom: 0.18rem;
    .home-notice-bar {
      font-size: 0.12rem;
      border: 0.01rem solid #1d2b50;
      border-radius: 0.1rem;
      .van-notice-bar__left-icon {
        color: #e5623a;
      }
    }
    .van-notice-bar {
      height: 0.24rem;
      line-height: 0.24rem;
      border-radius: 0.24rem;
    }
  }
  .home-tab-box-wrap {
    padding: 0 0.1rem;
    margin-bottom: 0.1rem;
    .home-tab-box {
      width: 100%;
      height: 0.4rem;
      background: url(../../assets/images/public/home-bg1.png) no-repeat;
      background-size: 100% 100%;
      padding: 0.18rem 0.32rem 0 0.32rem;
      box-sizing: border-box;
      .tab-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .tab-item {
          width: 1.14rem;
          height: 0.22rem;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url(../../assets/images/public/home-wxz.png) no-repeat;
          background-size: 100% 100%;
        }
        .active-item {
          background: url(../../assets/images/public/home-xz.png) no-repeat;
          background-size: 100% 100%;
        }
        .tab-item:nth-last-of-type(1) {
          transform: scaleX(-1);
          span {
            transform: scaleX(-1);
          }
        }
      }
    }
  }

  // .swiper-wrap {
  //   width: 140vw;
  //   position: relative;
  //   left: -20vw;
  //   .swiper {
  //     .van-swipe__track {
  //       .swiper-item {
  //         width: 100%;
  //         position: relative;
  //         cursor: pointer !important;
  //       }
  //     }
  //   }
  // }

  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.05rem 0.05rem 0.05rem 0.05rem;
    border-color: #e9e9e9 transparent transparent transparent;
    margin: 0.05rem auto;
  }

  .custom-indicator {
    position: absolute;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    cursor: pointer;

    i {
      display: block;
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 50%;
      background: #575e60;
      margin-right: 0.1rem;
    }

    .active-swiper {
      background: #d87b28;
    }
  }

  .playing-active {
    width: 100%;

    .playing-wrap {
      // width: 3.4rem;
      padding-top: 0.3rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .playing-box {
        display: flex;

        .play-item {
          position: relative;
          cursor: pointer;

          // .playing-item-img {
          //   width: 0.8rem;
          //   // height:1rem;
          // }
          &:nth-child(1) {
            .playing-item-img {
              width: 1rem;
            }
          }

          &:nth-child(2) {
            .playing-item-img {
              width: 1rem;
            }
          }

          &:nth-child(3) {
            .playing-item-img {
              width: 1rem;
            }
          }

          &:nth-child(4) {
            .playing-item-img {
              width: 1rem;
            }
          }

          .play-info {
            position: absolute;
            bottom: 35%;
            left: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            transform: translateX(-35%);

            span {
              font-size: 0.08rem;
            }

            img {
              height: 0.12rem;
              max-width: 100px;
            }
          }

          .play-info:nth-of-type(4) {
            // margin-top: 10px;
          }

          &:nth-child(1) {
            transform: translate(18%, 4%);

            .play-info {
              transform: translate(-30%, -101%);
            }
          }

          &:nth-child(2) {
            transform: translate(13%, 0%);

            .play-info {
              transform: translate(-60%, 10%);
            }
          }

          &:nth-child(3) {
            transform: translate(-5%, 0%);

            .play-info {
              transform: translate(-60%, 40%);
            }
          }

          &:nth-child(4) {
            .play-info {
              transform: translate(-85%, -278%);
            }

            transform: translate(-14%, 2%);
          }
        }
      }
    }
  }

  .home-box {
    width: 100%;
    .home-List-box {
      .Title {
        width: 100%;
        // height:0.3rem;
        // margin-top: 0.4rem;
        // height: 1rem;
        // margin-left: -1.6rem;
        // padding: 0.1rem;
        // margin-bottom: 0.4rem;

        .play-title {
          // width: 3.4rem;
          height: 0.35rem;
          margin: 0 auto;
        }
      }

      .home-List {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 0.135rem;
        justify-content: center;
        .home-list-item {
          width: 33.33%;
          position: relative;
          box-sizing: border-box;
          margin-bottom: 0.14rem;
          .tagbox {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            img {
              width: 0.26rem;
              height: 0.1rem;
            }
          }
          .left-icon {
            position: absolute;
            top: 0rem;
            left: 0rem;
          }

          .wrap_pic {
            // height: 1.5rem;
            height: 0.94rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            img {
              height: 100%;
            }

            .weapon_cover {
              position: absolute;
              height: 0.6rem;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                height: 100%;
              }
            }
          }

          .Blind_btn {
            text-align: center;
            margin-bottom: 0.08rem;
            color: #cecece;
            font-size: 0.12rem;
            margin-top: -0.1rem;
          }
          .box-price {
          }
        }
      }
    }
  }

  @keyframes zhuan {
    to {
      transform: rotate(1turn);
    }
  }
}
</style>
